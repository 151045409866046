import React, { useEffect, useState, Fragment } from 'react';
import { Box, } from '@mui/material';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { styled } from '@mui/system';

// Estilos para la cabecera en escala de grises
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    backgroundColor: '#e0e0e0', // Escala de grises
    color: theme.palette.common.black,
    fontWeight: 'bold',
}));

// Estilos para que la tabla sea responsiva
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
        overflowX: 'auto',
    },
}));

const Menu = () => {
    const [categorias, setCategorias] = useState([]);
    const [productos, setProductos] = useState([]);

    useEffect(() => {
        axios.get('https://mariscosdelvalle.com.mx/apis/menu.php', {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then((response) => {
                setCategorias(response.data.categorias);
                setProductos(response.data.productos);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);


    return (
        <Fragment>
            {categorias ?
                <Box sx={{ display: 'flex', justifyContent: 'center', height: '100vh', padding: 2 }}>
                    <StyledTableContainer component={Paper}>
                        {categorias.map((arrayCate, indexCate) => (
                            <Table key={indexCate} sx={{ borderBottom: '5px' }}>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Producto</StyledTableCell>
                                        <StyledTableCell align="center">Precio</StyledTableCell>
                                        <StyledTableCell align="right">Categoría</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                {productos.map((arrayProd, indexProd) => (
                                    arrayCate.categoria == arrayProd.categoria ?
                                        <TableBody>
                                            <TableRow key={indexProd}>
                                                <TableCell sx={{ width: '25%' }}>{arrayProd.producto}</TableCell>
                                                <TableCell align="center" sx={{ width: '25%' }}>{arrayProd.precio}</TableCell>
                                                <TableCell align="right" sx={{ width: '25%' }}>{arrayProd.categoria}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                        : null
                                ))}
                            </Table>
                        ))}
                    </StyledTableContainer>
                </Box>
                : null}
        </Fragment>
    );
};

export default Menu;