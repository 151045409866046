import React from 'react';
import { Box, TextField, Button, Typography, Container } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const GradientBackground = styled('div')({
    background: 'linear-gradient(135deg, #f0f0f0 0%, #d9d9d9 100%)',
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem', // Añadir padding para asegurar que no se pegue a los bordes en pantallas pequeñas
});

const FormContainer = styled(Container)(({ theme }) => ({
    backgroundColor: '#fff',
    padding: '2rem',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    maxWidth: '400px',
    [theme.breakpoints.down('sm')]: {
        padding: '1rem',
    },
}));

const UserForm = () => {
    const navigate = useNavigate();
    const handleSubmit = (event) => {
        event.preventDefault();
        const usuario = event.target.usuario.value;
        const nip = event.target.nip.value;
        const data = {
            usuario: usuario,
            nip: nip,
        };
        //CONSULTA DE ACCESO Y PERMISOS
        axios.post('https://mariscosdelvalle.com.mx/apis/login.php', data, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then((response) => {
                if (response.data === 'Usuario no encontrado') {
                    toast.warn('El usuario no existe', {
                        position: "top-center",
                        autoClose: 2000,
                        style: {
                            backgroundColor: '#f8d7da',
                            color: '#721c24',
                            border: '1px solid #f5c6cb',
                            borderRadius: '8px',
                        },
                    });
                } else {
                    if (response.data[0].Nip === nip) {
                        toast.success('Contraseña correcta', {
                            position: "top-center",
                            autoClose: 2000,
                        });
                        sessionStorage.setItem('Nombre', response.data[0].nombres);
                        navigate('/PuntoDeVenta');
                    } else {
                        toast.warn('Contraseña Incorrecta', {
                            position: "top-center",
                            autoClose: 2000,
                            style: {
                                backgroundColor: '#f8d7da',
                                color: '#721c24',
                                border: '1px solid #f5c6cb',
                                borderRadius: '8px',
                            },
                        });
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <GradientBackground>
            <FormContainer>
                <Typography variant="h4" component="h1" gutterBottom align="center">
                    Ingreso de Usuario
                </Typography>
                <form onSubmit={handleSubmit}>
                    <Box mb={2}>
                        <TextField
                            label="Usuario"
                            name="usuario"
                            variant="outlined"
                            fullWidth
                            required
                        />
                    </Box>
                    <Box mb={2}>
                        <TextField
                            label="NIP"
                            name="nip"
                            type="password"
                            variant="outlined"
                            fullWidth
                            required
                        />
                    </Box>
                    <Button type="submit" variant="contained" color="primary" fullWidth>
                        Ingresar
                    </Button>
                </form>
            </FormContainer>
            <ToastContainer></ToastContainer>
        </GradientBackground>
    );
};

export default UserForm;