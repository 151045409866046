import React, { useState } from 'react';
import { AppBar, Tabs, Tab, Toolbar, Typography, Box } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Pedidos from './componentes/Pedidos.jsx';
import Menu from './componentes/Menu.jsx';
// Crear un tema personalizado con un gradiente rojo
const theme = createTheme({
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    background: 'linear-gradient(to bottom, #1fa5ff, #1fa5ff, #1fa5ff, #1fa5ff, #1fa5ff);', // Gradiente
                },
            },
        },
    },
});

const PuntoDeVenta = () => {
    const [tabValue, setTabValue] = useState(1);
    const nombre = sessionStorage.getItem('Nombre');
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <ThemeProvider theme={theme}>
            <Box
                sx={{
                    flexGrow: 1,
                    minHeight: '100vh', // Asegura que el fondo ocupe toda la altura de la pantalla
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            >
                {/* Barra superior */}
                <AppBar position="static">
                    <Toolbar>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            Usuario: {nombre}
                        </Typography>
                    </Toolbar>
                </AppBar>

                {/* Tabs responsivos */}
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="tabs responsivos"
                    sx={{
                        justifyContent: 'center',  // Centrar los tabs
                        display: 'flex',
                        alignItems: 'center',      // Asegura que estén alineados verticalmente
                        '& .MuiTabs-flexContainer': {
                            justifyContent: 'center',  // Para centrar el contenedor de los tabs
                        },
                    }}
                >
                    <Tab label="PEDIDOS" />
                    <Tab label="MENÚ" />
                    <Tab label="PERSONAL" />
                    <Tab label="REPORTES" />
                </Tabs>

                {/* Contenido de los tabs */}
                <Box >
                    {tabValue === 0 && <Pedidos></Pedidos>}
                    {tabValue === 1 && <Menu></Menu>}
                    {tabValue === 2 && <Typography>Página de Personal</Typography>}
                    {tabValue === 3 && <Typography>Página de Reportes</Typography>}
                </Box>
            </Box>
        </ThemeProvider >
    );
};

export default PuntoDeVenta;