import React, { useState } from 'react';
import { Box, Fab, Modal, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

// Estilo para el círculo verde
const circleStyle = {
    backgroundColor: 'rgba(0, 128, 0, 0.2)', // Verde tenue
    width: { xs: '60px', md: '100px' }, // Tamaño responsivo: más pequeño en pantallas pequeñas
    height: { xs: '60px', md: '100px' },
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
};

// Estilo para el modal
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: 300, md: 400 }, // Ancho del modal responsivo
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const Pedidos = () => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', padding: 2 }}>
            {/* Círculo verde con ícono */}
            <Fab sx={circleStyle} onClick={handleOpen}>
                <FontAwesomeIcon icon={faPlus} size="2x" /> {/* Ícono de Font Awesome */}
            </Fab>

            {/* Modal */}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={modalStyle}>
                    <Typography id="modal-title" variant="h6" component="h2">
                        Agregar Pedido
                    </Typography>
                    <Typography id="modal-description" sx={{ mt: 2 }}>
                        Aquí puedes agregar los detalles del pedido.
                    </Typography>
                </Box>
            </Modal>
        </Box>
    );
};

export default Pedidos;
